import { navigate } from 'gatsby'
import { useEffect } from 'react'

const AtendimentoMovel = ({ data }) => {
	useEffect(() => {
		navigate('/atendimento-domiciliar')

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}

export default AtendimentoMovel
